<template>
  <div class="sidebar sidebar-mobile">
    <div class="sidebar-container">
      <div class="profile-menu">
        <div class="avatar">
          <img :src="user.photo | imageProfile" alt="profile" />
        </div>
        <div class="avatar-info">
          <h3>{{ user.name }} {{ user.lastName }}</h3>
        </div>
      </div>

      <div class="sidebar-menu">
        <ul>
          <li v-for="(item, i) in menu" :key="i">
            <router-link :to="item.path">
              <v-icon>{{ item.icon }}</v-icon>
              <span>{{ item.name }}</span>
              <v-badge
                color="error"
                v-if="item.badge && countNotify"
                inline
                :content="countNotify"
              ></v-badge>
            </router-link>
          </li>
          <li @click="btnCloseSesion()">
            <a>
              <v-icon>mdi-exit-to-app</v-icon>
              <span>Cerrar sesión</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <CustomDialog
      :dialog.sync="dialog"
      :title="'Cerrar sesión'"
      :message="'¿Estás seguro que quieres salir?'"
      @onChange="btnAcceptDialog($event)"
    ></CustomDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useMenu from "./useMenu";
import GlobalServise from "../../../../core/services/globals-services";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";

export default {
  name: "Sidebar",
  data: () => ({
    menu: useMenu,
    dialog: false,
  }),
  components: {
    CustomDialog,
  },
  computed: {
    ...mapState(["user", "countNotify"]),
  },
  created() {},
  methods: {
    btnCloseSesion() {
      this.dialog = true;
    },
    btnAcceptDialog(event) {
      if (event) {
        const { setLogout } = GlobalServise();
        setLogout(true);
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar-mobile {
  background: var(--color-darkLight);

  .profile-menu {
    .avatar {
      img {
        border: 2px solid #fff;
        box-shadow: inset 0 1.5px 3px 0 rgb(0 0 0 / 15%),
          0 1.5px 3px 0 rgb(0 0 0 / 15%);
        box-sizing: content-box;
      }
    }
  }

  .sidebar-menu {
    margin-top: 15px;

    li a {
      margin-left: 0rem !important;
      color: var(--color-menu-text) !important;
      font-weight: 300;
      font-size: 16px;

      i {
        color: var(--color-menu-icon) !important;
        font-size: 2.1rem !important;
      }
    }

    li:last-child {
      // position: absolute;
      // bottom: 16px;
      // display: block;
      // width: 90%;
    }

    li a.active i {
      margin-left: 0px !important;
    }
  }
}
</style>
