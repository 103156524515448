import { render, staticRenderFns } from "./bottom-bars.vue?vue&type=template&id=58420777&"
import script from "./bottom-bars.vue?vue&type=script&lang=js&"
export * from "./bottom-bars.vue?vue&type=script&lang=js&"
import style0 from "./bottom-bars.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VBottomNavigation,VBtn,VIcon})
