import axios from "axios";

const SettingServices = () => {
  const getSettingAll = async () => {
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/setting`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const saveSetting = async (data) => {
    return await axios
      .post(`${process.env.VUE_APP_ROOT_API}/setting`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  const updateSetting = async (id, data) => {
    return await axios
      .put(`${process.env.VUE_APP_ROOT_API}/setting/${id}`, data)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    getSettingAll,
    saveSetting,
    updateSetting,
  };
};

export default SettingServices;
