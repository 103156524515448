<template>
  <div class="bottom-navigation">
    <v-bottom-navigation app color="iconBar" grow fixed>
      <v-btn icon to="/mobile/mark">
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn icon to="/mobile/order">
        <v-icon>mdi-text-box</v-icon>
      </v-btn>

      <v-btn icon to="/mobile/cart">
        <v-badge
          bordered
          color="error"
          :content="cart.length"
          :value="cart.length"
        >
          <v-icon>mdi-cart-variant</v-icon>
        </v-badge>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BottomBars",
  data: () => ({}),
  computed: {
    ...mapState(["cart"]),
  },
};
</script>

<style lang="scss">
.v-item-group.v-bottom-navigation .v-btn {
  height: inherit !important;
}

.bottom-navigation {
  .theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
    color: inherit !important;
  }

  .v-item-group.v-bottom-navigation .v-btn.v-btn--active {
    color: var(--color-iconBarDark) !important;
  }

  .v-btn {
    i {
      font-size: 2.5rem !important;
    }
  }
}
</style>
