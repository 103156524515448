<template>
  <div class="mobile-base">
    <v-app-bar app dense color="headerMobile">
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <div class="logo-top">
        <img src="img/home/logo.png" alt="logo" />
      </div>
      <v-btn
        icon
        class="btn-notification"
        @click="btnNotify()"
        color="iconBarDark"
      >
        <v-badge
          v-if="countNotify"
          :content="countNotify"
          bordered
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>

        <v-icon v-if="!countNotify">mdi-bell</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      enable-resize-watcher
      width="300"
    >
      <Sidebar />
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <BottomBars />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BottomBars from "./layout/bottom-bars.vue";
import Sidebar from "./layout/sidebar.vue";
import SettingService from "../../../core/services/setting-services";
import SettingStorage from "../../../core/storage/setting-storage";
import UserStorage from "../../../core/storage/user-storage";
import NotificationServices from "../../../core/services/notification-services";

export default {
  name: "Mobile",
  data: () => ({
    sidebarMenu: false,
  }),
  computed: {
    ...mapState(["user", "countNotify"]),
  },
  components: {
    BottomBars,
    Sidebar,
  },
  async created() {
    const { userId } = UserStorage();
    this.getSettingLast();
    this.getListClientService();
    this.getListOrders();
    this.getListProducts();

    const user = await userId();
    this.$socket.$subscribe(user.connect, (resp) => {
      this.getCountNotify({ count: resp.data.count });
    });

    this.getCountNotifyAlert(user.connect);
    this.getNotify();
  },
  methods: {
    ...mapActions([
      "getSetting",
      "getListClientService",
      "getListOrders",
      "getListProducts",
      "getCountNotify",
      "getNotify",
    ]),
    getSettingLast() {
      const { getSettingAll } = SettingService();
      const { settingAdd } = SettingStorage();
      getSettingAll().then(async (resp) => {
        const response = resp.data;
        if (response.code === 200) {
          settingAdd(response.data[0]);
          await this.getSetting();
        }
      });
    },
    getCountNotifyAlert(user) {
      const { notificatioMobileCount } = NotificationServices();
      notificatioMobileCount(user).then((resp) => {
        const response = resp.data;
        if (response.code === 200) {
          this.getCountNotify({ count: response.data.count });
        }
      });
    },
    btnNotify() {
      this.$router.push("/mobile/notification").catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.mobile-base {
  .v-app-bar {
    .theme--light.v-btn.v-btn--icon {
      color: var(--color-darkLight);
    }
  }

  .logo-top {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
}
</style>
