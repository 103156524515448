const useMenu = [
  {
    name: "Notificaciones",
    path: "/mobile/notification",
    icon: "mdi-bell ",
    badge: true,
  },
  {
    name: "Calculadora de cambio",
    path: "/mobile/convert",
    icon: "mdi-calculator-variant",
  },
  {
    name: "Clientes",
    path: "/mobile/client",
    icon: "mdi-account-plus ",
  },
  {
    name: "Perfil",
    path: "/mobile/profile",
    icon: "mdi-account",
  },
];

export default useMenu;
